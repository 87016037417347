/* You can add global styles to this file, and also import other style files */

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: rgba(243, 243, 243, 0.932);
}

/* set the form field to 100% width */
.mat-mdc-form-field {
    width: 100% !important;
    font-size: 14px !important;
}

/* set the form field to white background */
.mat-mdc-text-field-wrapper {
    background-color: #f4f5f8 !important;
    border-radius: 10px !important;
}

/* smaller padding for text field */
.mat-mdc-text-field-wrapper.mdc-text-field--filled .mat-mdc-form-field-infix {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

/* remove default border for the text field */
.mdc-text-field--filled .mdc-line-ripple::before {
    border: none !important;
}

/* Show line ripple when there is an error */
.mdc-text-field--filled .mdc-text-field--error .mdc-line-ripple::before {
    border-style: solid !important;
}

.mat-mdc-form-field-infix {
    min-height: 13px !important;
}

/* smaller font size for select */
.mat-mdc-option {
    font-size: 14px !important;
}

.mat-mdc-select-value-text {
    font-size: 14px !important;
}

/* customize font for label */
label {
    font-size: 14px !important;
    font-weight: 500 !important;
}

/* mat-select panel style */
.select-panel{
    background-color: #F8F8FB !important;
    border: 1px #d1c7e4 solid;
    max-height: 200px !important;
}
.select-panel .mat-mdc-option > span {
    margin: 0 auto; /* align center */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.select-panel .mat-mdc-option-active {
    background-color: #e6dafd !important;
    font-weight: 500;
}
.select-panel .mat-mdc-option > span {
    color: #8959df;
}
.select-panel .mat-pseudo-checkbox {
    display: none !important;
}
.select-panel .mat-mdc-option {
    min-height: 32px !important;
}
.select-panel .mat-mdc-option:hover {
    background-color: #f2ebff !important;
}

div.mat-mdc-select-panel{
    background-color: #F8F8FB !important;
    border: 1px #d1c7e4 solid;
    max-height: 200px !important;
}
div.mat-mdc-select-panel .mat-mdc-option{
    color:#8268b1
}
div.mat-mdc-select-panel .mat-mdc-option:hover {
    background-color: #f2ebff !important;
}

.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
    border-radius: 20px !important;
    width: 150px !important;
    max-width: 150px !important;
    font-size: 12px !important;
    box-shadow: none !important;
}

.mat-mdc-standard-chip {
    background-color: #e6eaf3 !important;
}